




import { defineComponent, computed } from '@vue/composition-api';
import { extractKeyValuePairData } from '../utils';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const apiData = props.data;
    const fields = [
      'ADDRESS',
      'T_TNAME',
      'A_TNAME',
      'PV_TNAME',
      'EMAIL',
      'PHONE_NO',
      'FAX_NO',
    ];

    const propsData = extractKeyValuePairData(fields, apiData);
    Object.assign(propsData, { title: 'ข้อมูลติดต่อ' });
    return {
      propsData,
    };
  },
});
